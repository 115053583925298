import styles from './Home.css';

function Home() {
  return (
    <div className="home">
      <h1>Dash Dawntech</h1>

      <a href="https://dawntech.dev" target='blank'>
        <img className={styles.logo} src={'logo192.png'} alt='Dawntech'/>
      </a>
    </div>
  );
}

export default Home;
