import * as React from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@emotion/react';
import { getTheme, paletteTypes, MainHeaderless as MainLayout } from '@dawntech/react-layout';
import DawntechLogo from './logos';

import './theme.css';

function Layout({ appbarName, children }) {
  const [themeMode, setTheme] = React.useState('light');
  const [paletteType, setPalette] = React.useState(paletteTypes[3]);

  const setMode = (mode) => {
    window.localStorage.setItem('themeMode', mode);
    setTheme(mode);
  };

  const setThemePalette = (type = 'green') => {
    const palette = paletteTypes.indexOf(type) === -1 ? 'green' : type;
    window.localStorage.setItem('themePalette', palette);
    setPalette(palette);
  };

  const themeToggler = (custom) => {
    if (custom) {
      setMode(custom);
    } else {
      themeMode === 'light' ? setMode('dark') : setMode('light');
    }
  };

  React.useEffect(() => {
    const localTheme = window.localStorage.getItem('themeMode');
    localTheme ? setTheme(localTheme) : setMode('light');
    const localPalette = window.localStorage.getItem('themePalette');
    localPalette ? setPalette(localPalette) : setThemePalette('green');
  }, []);

  return (
    <ThemeProvider theme={getTheme(themeMode, paletteType)}>
      <CssBaseline />
      <Box>
        <MainLayout themeMode={themeMode} themeToggler={themeToggler} paletteType={paletteType}>
          <AppBar position="sticky" sx={{ backgroundColor: '#32484E', px: 5 }}>
            <Toolbar>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                {appbarName}
              </Typography>

              <Box
                display={'flex'}
                alignItems="baseline"
                component="a"
                underline="none"
                href="/"
                title="Dawntech"
                height={{ xs: 28, md: 32 }}
                width={116}
              >
                <img
                  src={DawntechLogo.bns}
                  alt="Dawntech"
                />
              </Box>
            </Toolbar>
          </AppBar>

          <Container sx={{ mt: 2, mb: 2 }} maxWidth={false}>
            {children}
          </Container>
        </MainLayout>
      </Box>
    </ThemeProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
