import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import Layout from './Template/Layout';
import { IconButton, Typography } from '@mui/material';

import Skeleton from '@mui/material/Skeleton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import './Dashboard.css';

export default function Dashboard() {
  let [appbarName, setAppbarName] = useState();

  let [loading, setLoading] = useState(true);
  let [configuration, setConfiguration] = useState({});
  let [widgets, setWidgets] = useState([]);

  let { client, dashboard } = useParams();

  useEffect(() => {
    fetch(`/dash/${client}/${dashboard}`)
      .then(res => res.json())
      .then(data => {
        setLoading(false);
        setConfiguration(data.configuration);
        setWidgets(data.configuration.widgets);

        setAppbarName(data.configuration.title || dashboard);
      });
  }, [client, dashboard]);

  function increaseColumn(e, i) {
    const newWidgets = widgets;
    const breakpoints = e.grid.breakpoints;

    if (breakpoints.xs < 12) {
      breakpoints.xs += 1;
    }

    if (breakpoints.sm < 12) {
      breakpoints.sm += 1;
    }

    if (breakpoints.md < 12) {
      breakpoints.md += 1;
    }

    if (breakpoints.lg < 12) {
      breakpoints.lg += 1;
    }

    const widget = e;
    widget.grid.breakpoints = breakpoints;
    newWidgets[i] = widget;
    setWidgets([...newWidgets]);
  }

  function decreaseColumn(e, i) {
    const newWidgets = widgets;
    const breakpoints = e.grid.breakpoints;

    if (breakpoints.xs > 1) {
      breakpoints.xs -= 1;
    }

    if (breakpoints.sm > 1) {
      breakpoints.sm -= 1;
    }

    if (breakpoints.md > 1) {
      breakpoints.md -= 1;
    }

    if (breakpoints.lg > 1) {
      breakpoints.lg -= 1;
    }

    const widget = e;
    widget.grid.breakpoints = breakpoints;
    newWidgets[i] = widget;
    setWidgets([...newWidgets]);
  }

  return (
    <Layout appbarName={appbarName} client={client} dashboard={dashboard}>
      <Grid container spacing={3}>
        {
         loading ?
          <Grid xs={12}  display="flex" justifyContent="center" alignItems="center">
            <Box width={300}>
              <Typography variant="subtitle2">Carregando...</Typography>
              <Skeleton variant="text" sx={{ fontSize: '1rem', my: 1 }}/>
              <Skeleton variant="rounded" width={300} height={60} sx={{ my: 1 }}/>
              <Skeleton variant="rounded" width={300} height={60} sx={{ my: 1 }}/>
            </Box>
          </Grid>
          :
          <> 
            {
              widgets.map((e, i) => (
                <Grid key={i} style={{ transition: 'width .5s', ...e.grid?.style }} xs={e.grid?.breakpoints?.xs} sm={e.grid?.breakpoints?.sm} md={e.grid?.breakpoints?.md} lg={e.grid?.breakpoints?.lg}>
                  <Paper style={{ position: 'relative' }}>
                    {
                      !configuration['show-column-picker'] ?
                        <></> :
                        <Box style={{ position: 'absolute', right: 0 }}>
                          <IconButton size="small" onClick={() => increaseColumn(e,i)}><AddIcon fontSize="small"/></IconButton>
                          <IconButton size="small" onClick={() => decreaseColumn(e,i)}><RemoveIcon fontSize="small"/></IconButton>
                        </Box>
                    }

                    <iframe src={e.iframe?.src} scrolling="no"
                      style={{ margin: 0, padding: 0, border: 0, overflow: 'hidden', ...e.iframe?.style}}
                      title={e.iframe?.src} height={e.iframe?.height} width={e.iframe?.width}
                    ></iframe>
                  </Paper>
                </Grid>
              ))
            }

            {
              !configuration['show-config'] ? <></> :
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Dashboard Info
                </AccordionSummary>
                <AccordionDetails >
                  <code style={{ whiteSpace: "pre-wrap"}}>
                    {JSON.stringify(configuration, null, 4)}
                  </code>
                </AccordionDetails>
              </Accordion>
            }
          </>
        }
      </Grid>
    </Layout>
  );
}

